import {
  ArrowRight,
  CaretDown,
  CaretRight,
  Check,
  List,
  ShieldCheck,
  Timer,
  Medal,
  Phone,
  X,
  SignIn,
} from 'phosphor-react';

export type TIconName = keyof typeof icons;

const icons = {
  arrowRight: ArrowRight,
  caretDown: CaretDown,
  caretRight: CaretRight,
  check: Check,
  list: List,
  shieldCheck: ShieldCheck,
  timer: Timer,
  medal: Medal,
  phone: Phone,
  signIn: SignIn,
  x: X,
};

export const Icon = ({
  className,
  name,
}: {
  className?: string;
  name: TIconName;
}) => {
  const Component = icons[name];

  return <Component className={className} />;
};
