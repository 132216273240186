import {
  type TFormApiSetValuesPayload,
  type TFormApiOpenParams as TFormApiOpenParamsBase,
  type TFormApiTrackAnalyticsEventParams,
  type TFormApi,
  type TFormApiMethodArgs,
  callFormApiMethod,
} from '@insurance/form-api';
import { logger } from '@/utils/logger';

interface TFormApiOpenParams extends TFormApiOpenParamsBase {
  values?: TFormApiSetValuesPayload;
}

const callFormApiSafe = <M extends keyof TFormApi>(
  method: M,
  ...args: TFormApiMethodArgs<M>
) =>
  callFormApiMethod(method, ...args).catch((error) =>
    logger.error(error, 'Form api method call failed'),
  );

export const formApi = {
  openForm: ({
    values,
    baseUrl,
    withNextStep,
    isSecondTabApply,
  }: TFormApiOpenParams) => {
    if (values) {
      callFormApiSafe('setValues', values);
    }

    callFormApiSafe('openForm', {
      baseUrl,
      withNextStep,
      isSecondTabApply,
    });
  },
  closeForm: () => {
    callFormApiSafe('closeForm');
  },
  setValues: (payload: TFormApiSetValuesPayload) => {
    callFormApiSafe('setValues', payload);
  },
  trackAnalyticsEvent: (params: TFormApiTrackAnalyticsEventParams) => {
    callFormApiSafe('trackAnalyticsEvent', params);
  },
  openBankRateOfferWall: () => {
    callFormApiSafe('openBankRateOfferWall');
  },
  getAnalyticsParams: () => callFormApiSafe('getAnalyticsParams'),
};
