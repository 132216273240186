import { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_THRESHOLD = 5_000;

const INACTIVITY_EVENT_NAMES = [
  'mousemove',
  'mousedown',
  'touchstart',
  'click',
  'keydown',
  'scroll',
];

const subscribe = (callback: () => void) => {
  INACTIVITY_EVENT_NAMES.forEach((eventName) =>
    window.addEventListener(eventName, callback),
  );
};

const unsubscribe = (callback: () => void) => {
  INACTIVITY_EVENT_NAMES.forEach((eventName) =>
    window.removeEventListener(eventName, callback),
  );
};

type TUseInactivityModalStateOptions = {
  /** Control hook activity, enabled by default */
  enabled?: boolean;
  /** Inactivity threshold in milliseconds, default 5000ms */
  threshold?: number;
};

export const useInactivityModalState = ({
  enabled = true,
  threshold = DEFAULT_THRESHOLD,
}: TUseInactivityModalStateOptions = {}) => {
  const timeoutId = useRef(0);

  const [opened, setOpened] = useState(false);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const restart = useCallback(() => {
    window.clearTimeout(timeoutId.current);

    timeoutId.current = window.setTimeout(() => {
      unsubscribe(restart);
      open();
    }, threshold);
  }, [threshold, open]);

  const clear = useCallback(() => {
    window.clearTimeout(timeoutId.current);
    unsubscribe(restart);
  }, [restart]);

  const close = useCallback(() => {
    clear();
    setOpened(false);
  }, [clear]);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    restart();

    subscribe(restart);

    return () => clear();
  }, [enabled, restart, clear]);

  return {
    opened,
    open,
    close,
    restart,
  } as const;
};
