import { DEFAULT_INSURANCE_HOST, InsuranceHost } from '@insurance/utils';
import { useAppConfig } from '@insurance/app-config';

const hostToMailMap: Record<InsuranceHost, string> = {
  [InsuranceHost.Requote]: 'contact@requote.com',
  [InsuranceHost.MyInsuranceMarket]: 'contact@myinsurancemarket.com',
  [InsuranceHost.Scalefind]: 'contact@scalefind.com',
  [InsuranceHost.MyAutoArmor]: 'contact@myautoarmor.com',
  [InsuranceHost.CarSurefy]: 'contact@requote.com',
  [InsuranceHost.AllCheapQuotes]: 'constact@allcheapquotes.com',
  [InsuranceHost.SolaceAuto]: 'constact@solaceauto.com',
  [InsuranceHost.PragmaAuto]: 'constact@pragmaauto.com',
};

export const useContactEmail = (): string => {
  const { host } = useAppConfig();

  return hostToMailMap[host] ?? hostToMailMap[DEFAULT_INSURANCE_HOST];
};
