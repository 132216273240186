import { type TFormApi, type TFormApiMethodArgs } from './types';
import { getGlobalApi } from './utils/get-global-api';
import { waitGlobalApi } from './utils/wait-global-api';
import { callGlobalMethod } from './utils/call-global-method';

export const callFormApiMethod = <M extends keyof TFormApi>(
  method: M,
  ...args: TFormApiMethodArgs<M>
): Promise<ReturnType<TFormApi[M]>> => {
  if (getGlobalApi()) {
    return callGlobalMethod(method, ...args);
  }

  return waitGlobalApi().then(() => callGlobalMethod(method, ...args));
};
