// WARN: keep in sync with apps/form/src/types/form-config.ts
import { type TPostLeadFormResponse } from '../api/post-lead-form';

export type TFormBuildName = 'zen';

export const enum FORM_ROUTING_ID {
  FULL_FORM = 'fullForm',
  REDUCED_FORM = 'reducedForm',
  APP_FORM = 'appForm',
  OFFER_WALL = 'offerWall',
}

export type TFormConfigModalFormMode = 'backdrop' | 'fullbody';

export interface TFormConfigAvailableFeatures {}

export interface TFormConfig {
  routingId?: FORM_ROUTING_ID;
  isModalForm?: boolean;
  modalFormMode?: TFormConfigModalFormMode;
  postLeadFormResponse?: TPostLeadFormResponse;
  landingBuildTag?: string;
  enabledFeatures?: Partial<TFormConfigAvailableFeatures>;
}

declare global {
  interface Window {
    _ins_car_form_config_?: TFormConfig;
    _ins_car_init_promise_: Promise<void>;
  }
}
