import {
  type TFormApi,
  type TFormApiMethodArgs,
  type TFormApiMethod,
} from '../types';
import { getGlobalApi } from './get-global-api';

export const callGlobalMethod = <M extends keyof TFormApi>(
  method: M,
  ...args: TFormApiMethodArgs<M>
): Promise<ReturnType<TFormApi[M]>> => {
  try {
    const methodFn = getGlobalApi()?.[method] as TFormApiMethod<M> | undefined;

    if (!methodFn) {
      throw new Error(`formApi method "${method}" is not defined`);
    }

    const result = methodFn(...args);

    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error as Error);
  }
};
