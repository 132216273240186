import { useEffect, useState } from 'react';
import { getUrlParams } from '@insurance/utils';
import { isValidParams } from '@/utils/url';

type TUseLandingContentParams = {
  defaultTitle?: React.ReactNode;
  defaultSubtitle?: React.ReactNode;
  defaultButtonText?: React.ReactNode;
};

export const useLandingContent = ({
  defaultTitle,
  defaultSubtitle,
  defaultButtonText,
}: TUseLandingContentParams) => {
  const [content, setContent] = useState<Record<string, React.ReactNode>>({
    title: defaultTitle,
    subtitle: defaultSubtitle,
    buttonText: defaultButtonText,
  });

  useEffect(() => {
    const newContent: Record<string, React.ReactNode> = {};

    const urlParams = getUrlParams();

    if (isValidParams(urlParams.title)) {
      newContent.title = urlParams.title;
    }

    if (isValidParams(urlParams.sub_title)) {
      newContent.subtitle = urlParams.sub_title;
    }

    if (isValidParams(urlParams.button_text)) {
      newContent.buttonText = urlParams.button_text;
    }

    if (Object.keys(newContent).length > 0) {
      setContent((prev) => ({ ...prev, ...newContent }));
    }
  }, []);

  return content;
};
