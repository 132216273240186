import { type NextPageContext } from 'next';
import { type TFormBuildName } from '@/types/form-config';
import { isDevelopmentMode } from '@/utils/app';
import { FORM_SPLIT_TEST_COOKIE_NAME } from '@/utils/headers';

type TGetFormScriptSrcParams = {
  buildName: TFormBuildName;
  buildTag: string;
};

export const getFormScriptSrc = ({
  buildName,
  buildTag,
}: TGetFormScriptSrcParams): string =>
  `/frontend/form/${isDevelopmentMode() ? 'app' : buildName}.js?bt=${buildTag}`;

export const provideServerSideFormSplitTestCookie = (
  ctx: NextPageContext,
  cookie?: string,
) => {
  if (
    cookie &&
    !ctx.req?.headers.cookie?.includes(FORM_SPLIT_TEST_COOKIE_NAME)
  ) {
    ctx.res?.setHeader('set-cookie', cookie);
  }
};
