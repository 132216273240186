import { type FC, type HTMLProps } from 'react';
import cn from 'classnames';
import { Icon, type TIconName } from '@/components/common/icon';

import s from './style.module.scss';

type TButtonProps = Omit<
  HTMLProps<HTMLButtonElement & HTMLAnchorElement>,
  'size' | 'label'
> & {
  className?: string;
  iconEnd?: TIconName;
  iconStart?: TIconName;
  label?: React.ReactNode;
  size?: 'md' | 'lg';
  variant?: 'fill' | 'outline' | 'clear';
  disabled?: boolean;
  color?: 'primary' | 'primaryLight' | 'neutral';
  href?: string;
};

export const Button: FC<TButtonProps> = ({
  className,
  iconEnd,
  iconStart,
  label,
  size = 'md',
  variant = 'fill',
  color = 'primary',
  href,
  disabled = false,
  ...props
}) => {
  const RootElement = href ? 'a' : 'button';

  return (
    <RootElement
      className={cn(
        className,
        s.root,
        s[`size_${size}`],
        s[`variant_${variant}`],
        s[`color_${color}`],
      )}
      disabled={disabled}
      {...props}
      type="button"
      href={href}
    >
      <span className={s.inner}>
        {iconStart && (
          <Icon className={cn(s.icon, s.icon_start)} name={iconStart} />
        )}
        <span className={s.label}>{label}</span>
        {iconEnd && <Icon className={cn(s.icon, s.icon_end)} name={iconEnd} />}
      </span>
    </RootElement>
  );
};
