import getConfig from 'next/config';
import Script from 'next/script';
import {
  type TFormConfig,
  type TFormConfigModalFormMode,
  FORM_ROUTING_ID,
} from '@/types/form-config';
import { getFormScriptSrc } from '@/utils/form';
import { type TPostLeadFormResponse } from '@/api/post-lead-form';
import React from 'react';

const { publicRuntimeConfig } = getConfig();

type TFormScriptBaseProps = {
  routingId: FORM_ROUTING_ID;
  formBuildTag: string;
  isModalForm?: boolean;
  modalFormMode?: TFormConfigModalFormMode;
  postLeadFormResponse?: TPostLeadFormResponse;
};

export const FormScriptBase: React.FC<TFormScriptBaseProps> = ({
  routingId,
  formBuildTag,
  isModalForm = false,
  modalFormMode = 'fullbody',
  postLeadFormResponse,
}) => {
  const src = getFormScriptSrc({
    buildName: 'zen',
    buildTag: formBuildTag,
  });

  const config: TFormConfig = {
    routingId,
    isModalForm,
    modalFormMode,
    postLeadFormResponse,
    landingBuildTag: publicRuntimeConfig.buildTag,
  };

  return (
    <>
      <Script id="_ins_car_form_config_script_">
        {`window._ins_car_form_config_ = ${JSON.stringify(config)};`}
      </Script>

      <div id="pr-lead-form-v3" />

      <Script src={src} />
    </>
  );
};

type TFullFormScriptProps = Pick<
  TFormScriptBaseProps,
  'formBuildTag' | 'isModalForm' | 'modalFormMode' | 'postLeadFormResponse'
>;

export const FullFormScript: React.FC<TFullFormScriptProps> = ({
  formBuildTag,
  isModalForm,
  modalFormMode,
  postLeadFormResponse,
}) => (
  <FormScriptBase
    routingId={FORM_ROUTING_ID.FULL_FORM}
    formBuildTag={formBuildTag}
    isModalForm={isModalForm}
    modalFormMode={modalFormMode}
    postLeadFormResponse={postLeadFormResponse}
  />
);

type TReducedFormScriptProps = Pick<
  TFormScriptBaseProps,
  'formBuildTag' | 'isModalForm' | 'modalFormMode' | 'postLeadFormResponse'
>;

export const ReducedFormScript: React.FC<TReducedFormScriptProps> = ({
  formBuildTag,
  isModalForm,
  modalFormMode,
  postLeadFormResponse,
}) => (
  <FormScriptBase
    routingId={FORM_ROUTING_ID.REDUCED_FORM}
    formBuildTag={formBuildTag}
    isModalForm={isModalForm}
    modalFormMode={modalFormMode}
    postLeadFormResponse={postLeadFormResponse}
  />
);

type TAppFormScriptProps = Pick<
  TFormScriptBaseProps,
  'formBuildTag' | 'isModalForm' | 'modalFormMode' | 'postLeadFormResponse'
>;

export const AppFormScript: React.FC<TAppFormScriptProps> = ({
  formBuildTag,
  isModalForm,
  modalFormMode,
  postLeadFormResponse,
}) => (
  <FormScriptBase
    routingId={FORM_ROUTING_ID.APP_FORM}
    formBuildTag={formBuildTag}
    isModalForm={isModalForm}
    modalFormMode={modalFormMode}
    postLeadFormResponse={postLeadFormResponse}
  />
);

type TOfferWallScriptProps = Pick<
  TFormScriptBaseProps,
  'formBuildTag' | 'isModalForm' | 'modalFormMode'
>;

export const OfferWallScript: React.FC<TOfferWallScriptProps> = ({
  formBuildTag,
  isModalForm,
  modalFormMode,
}) => (
  <FormScriptBase
    routingId={FORM_ROUTING_ID.OFFER_WALL}
    formBuildTag={formBuildTag}
    isModalForm={isModalForm}
    modalFormMode={modalFormMode}
  />
);
