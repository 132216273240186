import { type HTMLAttributes } from 'react';
import cn from 'classnames';

import s from './style.module.scss';

type TRequoteLogoProps = HTMLAttributes<SVGAElement> & {
  firstLettersColor?: string;
  otherLettersColor?: string;
};

export const RequoteLogo = ({
  className,
  firstLettersColor = '#AA80FF',
  otherLettersColor = '#FFFFFF',
}: TRequoteLogoProps) => (
  <svg
    className={cn(className, s.root)}
    width="1034"
    height="253"
    viewBox="0 0 1034 253"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="1034" height="253" fill="none" />
    <path
      d="M1027.88 142.07L1033.62 145.346C1025.42 173.745 1006.49 192.86 964.531 192.86C917.354 192.86 891.767 164.252 891.767 123.501C891.767 77.3528 923.298 48.9219 966.715 48.9219C1010.13 48.9219 1032.52 77.5619 1032.52 112.578V121.799L937.497 124.529C940.501 148.832 955.793 161.184 983.099 161.184C1002.49 161.184 1016.41 155.177 1027.88 142.07ZM963.166 59.8445C944.87 59.8445 937.497 83.3603 936.951 110.121L982.007 107.39C981.734 80.0835 978.457 59.8445 963.166 59.8445Z"
      fill={otherLettersColor}
    />
    <path
      d="M845.242 192.86C827.493 192.86 805.74 182.483 805.74 155.723V67.8073H786.625L786.625 55.7802L861.445 0.347656V50.3189H896.575V71.0319H861.445V166.372L892.584 171.395V179.823C876.341 189.402 861.537 192.86 845.242 192.86Z"
      fill={otherLettersColor}
    />
    <path
      d="M710.671 192.86C661.246 192.86 631.566 164.554 631.566 121.955C631.566 78.2646 659.913 47.5889 709.338 47.5889C758.763 47.5889 787.836 76.2289 787.836 118.827C787.836 162.518 760.096 192.86 710.671 192.86ZM710.43 179.604C728.452 179.604 735.52 163.096 735.52 131.147C735.52 87.1835 727.087 60.8449 708.245 60.8449C689.95 60.8449 682.882 77.3531 682.882 109.302C682.882 153.266 691.315 179.604 710.43 179.604Z"
      fill={otherLettersColor}
    />
    <path
      d="M616.705 177.022L633.089 183.03V190.129L561.545 192.86V161.731C550.077 176.203 532.6 192.86 505.567 192.86C476.349 192.86 459.965 180.572 459.965 155.723V67.7956L449.07 64.7077V52.8415L515.397 50.3193V151.9C515.397 159.819 520.039 164.461 535.604 164.461C545.161 164.461 553.627 159.546 561.545 152.992V67.7956L545.161 63.1535V53.05L616.705 50.3193V177.022Z"
      fill={otherLettersColor}
    />
    <path
      d="M437.289 238.228L453.673 242.87V252.974H357.554V242.87L382.13 237.409V167.731C376.509 181.931 362.792 192.899 337.123 192.899C307.314 192.899 276.503 170.781 276.503 126.271C276.503 72.0898 301.958 47.2943 358.164 47.2943C382.13 47.2943 395.383 51.884 395.383 51.884L437.289 50.3794V238.228ZM364.38 170.189C370.661 170.189 376.668 167.185 382.13 160.359V66.1961C377.488 62.1001 371.753 60.9156 363.015 60.9156C339.258 60.9156 327.516 82.8532 327.516 111.798C327.516 147.843 341.443 170.189 364.38 170.189Z"
      fill={otherLettersColor}
    />
    <path
      d="M262.837 142.07L268.572 145.346C260.38 173.745 240.109 192.86 202.152 192.86C157.643 192.86 128.055 166.919 128.055 123.501C128.055 77.3528 160.919 47.5885 204.337 47.5885C247.755 47.5885 267.479 74.8952 267.479 112.578V121.799L175.119 124.529C178.123 148.832 193.414 161.184 220.721 161.184C240.109 161.184 251.368 155.177 262.837 142.07ZM200.787 58.5112C182.492 58.5112 175.119 83.3603 174.573 110.121L219.629 107.39C219.356 80.0835 216.079 58.5112 200.787 58.5112Z"
      fill={firstLettersColor}
    />
    <path
      d="M119.253 47.5889C131.814 47.5889 134.272 57.1462 134.272 66.7035C134.272 78.9915 129.903 89.6091 115.157 89.6091C103.962 89.6091 86.7583 90.4283 72.0127 104.355V171.561L96.5887 180.026V190.13H0.469238V180.026L16.8532 175.384V81.4491L0.469238 78.1723V71.0726L72.0127 47.5889V88.3078C85.6661 56.6321 98.2271 47.5889 119.253 47.5889Z"
      fill={firstLettersColor}
    />
  </svg>
);
