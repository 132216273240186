import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

import { Icon } from '@/components/common/icon';
import { Button } from '@/components/common/button';
import { inter } from '@/styles/fonts';

import s from './style.module.scss';

export type TModalProps = {
  className?: string;
  children?: React.ReactNode;
  isShown?: boolean;
  title?: string;
  subtitle?: string;
  onClose?: () => void;
  actions?: {
    isPrimary: boolean;
    label: string;
    onClick: () => void;
    variant?: 'fill' | 'outline' | 'clear';
    color?: 'primary' | 'primaryLight' | 'neutral';
  }[];
  template?: React.ReactNode;
};

const Modal = ({
  className,
  children,
  isShown,
  title = '',
  subtitle = '',
  onClose,
  actions,
  template,
}: TModalProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  const defaultTemplate = (
    <div
      className={cn(
        className,
        s.root,
        { [s.isShown]: isShown },
        inter.className,
      )}
    >
      <button className={s.button_close} type="button" onClick={onClose}>
        <Icon className={s.buttonIcon} name="x" />
      </button>

      <div className={s.main}>
        {(title || subtitle) && (
          <div className={s.header}>
            {title && <h2 className={s.title}>{title}</h2>}
            {subtitle && <p className={s.subtitle}>{subtitle}</p>}
          </div>
        )}

        {children && <div className={s.body}>{children}</div>}

        {actions && (
          <div className={s.actionsBlock}>
            {actions.map((action) => (
              <Button
                className={s.action}
                label={action.label}
                size="lg"
                onClick={action.onClick}
                key={action.label}
                variant={action.variant ?? 'fill'}
                color={action.color ?? 'primary'}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return mounted
    ? createPortal(template || defaultTemplate, document.body)
    : null;
};

export default Modal;
