export const enum AnalyticsGoals {
  LandingClickButton = 'landingClickButton',
  LandingFieldIsFilled = 'landingFieldIsFilled',
  LandingGoToForm = 'landingGoToForm',
  LandingClickCall = 'landingClickCall',
  ShowOfferWall = 'showOfferWall',
  ClickOfferInOfferWall = 'clickOfferInOfferWall',
  InactivityDialogOpened = 'inactivityDialogOpened',
  LandingTalkToUsClick = 'landingTalkToUsClick',
  LandingFiltersChange = 'filters',
}

export const API_WAITING_TIMEOUT = 400 * 1000;

export const API_CHECK_INTERVAL = 100;
