import { useCallback, useRef, useState } from 'react';

interface TUseToggleInterface {
  enabled: boolean;
  wasEnabledOnce: () => boolean;
  enable: () => void;
  disable: () => void;
}

export const useToggle = (): TUseToggleInterface => {
  const [enabled, setEnabled] = useState(false);

  const enabledOnce = useRef(false);

  const wasEnabledOnce = useCallback(() => enabledOnce.current, []);

  const enable = useCallback(() => {
    setEnabled(true);
    enabledOnce.current = true;
  }, []);

  const disable = useCallback(() => {
    setEnabled(false);
  }, []);

  return {
    enabled,
    enable,
    disable,
    wasEnabledOnce,
  };
};
